"use strict";
/**
 * Initialises the Submenus within the primary Wagtail menu (excluding the Explorer menu)
 */
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
exports.__esModule = true;
exports.initSubmenus = void 0;
var initSubmenus = function () {
    var primaryNavContainer = document.querySelector('[data-nav-primary]');
    if (!primaryNavContainer) {
        return;
    }
    var subMenuTriggers = document.querySelectorAll('[data-nav-primary-submenu-trigger]');
    var activeClass = 'submenu-active';
    __spreadArrays(subMenuTriggers).forEach(function (subMenuTrigger) {
        subMenuTrigger.addEventListener('click', function (clickEvent) {
            var submenuContainer = subMenuTrigger.parentNode;
            primaryNavContainer.classList.remove(activeClass);
            __spreadArrays(subMenuTriggers).forEach(function (sm) { return sm.classList.remove(activeClass); });
            primaryNavContainer.classList.toggle(activeClass);
            submenuContainer.classList.toggle(activeClass);
            document.addEventListener('mousedown', function (e) {
                if (!submenuContainer.contains(e.target) &&
                    subMenuTrigger !== e.target) {
                    primaryNavContainer.classList.remove(activeClass);
                    submenuContainer.classList.remove(activeClass);
                }
            });
            document.addEventListener('keydown', function (e) {
                // IE11 uses "Esc" instead of "Escape"
                if (e.key === 'Escape' || e.key === 'Esc') {
                    primaryNavContainer.classList.remove(activeClass);
                    submenuContainer.classList.remove(activeClass);
                }
            });
            clickEvent.preventDefault();
        });
    });
};
exports.initSubmenus = initSubmenus;
