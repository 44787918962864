"use strict";
exports.__esModule = true;
exports.createAction = void 0;
// Returns the value of the first argument. All others are ignored.
function identity() {
    var func = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        func[_i] = arguments[_i];
    }
    return func[0];
}
// Stolen from the following project (had a 18kb footprint at the time).
// https://github.com/acdlite/redux-actions/blob/79c68635fb1524c1b1cf8e2398d4b099b53ca8de/src/createAction.js
function createAction(type, actionCreator, metaCreator) {
    if (actionCreator === void 0) { actionCreator = identity; }
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var action = {
            type: type,
            payload: actionCreator.apply(void 0, args),
        };
        if (action.payload instanceof Error) {
            // Handle FSA errors where the payload is an Error object. Set error.
            action.error = true;
        }
        if (typeof metaCreator === 'function') {
            action.meta = metaCreator.apply(void 0, args);
        }
        return action;
    };
}
exports.createAction = createAction;
