"use strict";
exports.__esModule = true;
exports.initFocusOutline = void 0;
var OUTLINE_ON = 'focus-outline-on';
var OUTLINE_OFF = 'focus-outline-off';
var toggleFocusOutline = function (isOn) {
    document.body.classList.toggle(OUTLINE_OFF, !isOn);
    document.body.classList.toggle(OUTLINE_ON, isOn);
};
var removeFocusOutline = toggleFocusOutline.bind(null, false);
var addFocusOutline = toggleFocusOutline.bind(null, true);
/**
 * Adds a heavy focus outline to the UI, only for users who tab through the page.
 * The outline is not useful with touch or mouse input – these remove the outline.
 */
exports.initFocusOutline = function () {
    // Focus outline styles are added by default in the HTML, so they work without JS enabled.
    removeFocusOutline();
    window.addEventListener('mousedown', removeFocusOutline);
    window.addEventListener('touchstart', removeFocusOutline);
    window.addEventListener('keydown', function (e) {
        var isTabKey = e.keyCode === 9;
        if (isTabKey) {
            addFocusOutline();
        }
    });
};
